import { wrapper } from '../store';
import { getCommonServerSideProps } from '../api/getMainServerSideProps';
import { getParseUrl, PageTypeByUrl } from '../api'
import { HomePage, LeaguePage, MatchPage, PageFifaRanking } from '../feature-pages'

const getComponentByMethod = (type: PageTypeByUrl, props: any) => {
    switch (type) {
    case 'match':
        return <MatchPage {...props} />
    case 'competition':
        return <LeaguePage {...props} />
    case 'ranking':
        return <PageFifaRanking {...props} />
    default:
        return <HomePage {...props} />
    }
}

const getServerSidePropsByMethod = async (type: PageTypeByUrl, store: any, context: any) => {
    switch (type) {
    case 'match':
        return await MatchPage.getSSRProps(store, context);
    case 'competition':
        return await LeaguePage.getSSRProps(store, context);
    case 'ranking':
        return await PageFifaRanking.getSSRProps(store);
    default:
        return await HomePage.getSSRProps(store, context);
    }
}

export default function DynamicRouteHandler(_props: any) {
    return getComponentByMethod(_props.method, _props);
}

export const getServerSideProps = wrapper.getServerSideProps(
    (store) => async (context) => {
        const {req} = context
        const commonProps = await getCommonServerSideProps(store, context);

        let method: PageTypeByUrl = '_main';
        let meta: any = null
        let hreflang: any = null
        const { res } = context
        try {
            const url = `/${context.locale}${context.resolvedUrl}`;
            const data = await store.dispatch(
                getParseUrl.initiate({ url: url, locale: context.locale })
            );

            // return redirect
            if (data.data.data.url && data.data.data.url !== context.resolvedUrl) {
                res.writeHead(301, { Location: `/${context.locale}${data.data.data.url}` })
                res.end()
            }

            method = data.data.data.api_method;

            // modify context by id for SSR
            context.query.id = data.data.data.parameters;
            // @ts-ignore
            commonProps.props.id = data.data.data.parameters;
            meta = data.data.data.meta
            hreflang = data.data.data.hreflang
        } catch (e) {
            return { // <-----------------does the trick here!!
                notFound: true,
            }
        }

        // get serversideprops by page
        const addProps = await getServerSidePropsByMethod(method, store, context);
        //@todo Вынести домен в .env
        const domain = process.env.MODE === 'development' ? req.headers.host : 'oddsocean.com'

        return {
            props: {
                ...commonProps.props,
                method: method,
                meta,
                hreflang,
                baseUrl: `https://${domain}`,
                ...addProps,
            },
        }
    }
)